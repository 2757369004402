 export const skillsData = [
    'AWS',
    'Go',
    'Docker',
    'GCP',
    'Azure',
    'Figma',
    'Javascript',
    'React',
    'Django',
    'C++',
    'Python',
    'Java',
    'Flutter',
    'Git',
    'MongoDB',
    'MySQL',    
    'Firebase',
    'OpenCV',
    'Tensorflow',
 ]
